<template>
  <div >
    <div class="w-full relative">
    <input
      @input="onChanged"
      v-model="state.search"
      @keyup.enter="emitSearch"
      type="text"
      name="taxpayer"
      placeholder="Enter tax payer's name to filter"
      class="w-full h-12 px-4 pr-10 border-2 text-gray-700 relative font-medium border-gray-400 rounded-lg focus:outline-none focus:ring-1 focus:ring-gray-600"
    />
    <button class="icon-button h-12 right-0 my-auto absolute border-2 border-gray-400 bg-gray-200 leading-snug font-normal text-center text-gray-400 bg-transparent rounded-tr-lg rounded-br-lg text-base " @click="emitSearch">
    <i class="fas fa-search items-center justify-center"></i>
  </button>
  </div>
  
  <ul id="autocomplete-results" v-show="state.isOpen" class="autocomplete-results">
    <li class="loading" v-if="state.isLoading">
      Loading results...
    </li>
    <li
      v-else
      v-for="(result, i) in state.results"
      :key="i"
      @click="setResult(result)"
      class="autocomplete-result"
      :class="{ 'is-active': i === state.arrowCounter }"
    >
      {{ result.customer_name }}
    </li>
  </ul>
  </div>
</template>

<script>
import {reactive, onMounted} from "vue";
import {useRoute} from "vue-router";
// import {useStore} from "vuex";
export default {
  setup(props,context){
    const state = reactive({
      search: "",
      isOpen: false,
      // isLoading: false,
      // results: [],
      arrowCounter:0
    });
    const route = useRoute();

    // const store = useStore();

    const onChanged = async() =>{
      // state.isLoading = true;
      // const res = await store.dispatch('searchPayer', {isStale:true, criteria:state.search})
      // state.isLoading = false;
      // state.results = res
      // state.isOpen = state.search.length > 0 ? true :false
    }
    const setResult = (result) =>{
      state.search = result.customer_name;
      emitSearch();
    }

    const emitSearch = () =>{
      state.isOpen = false;
       context.emit('search', state.search)
    }

    onMounted(() => {
      try {
        const name = route.params.name || '';
        state.search = name;
      } catch (error) { 
        console.log(error);
      }
    });

    return {state, onChanged, setResult, emitSearch}

  },
};
</script>
<style>
.icon-button{
  width:calc(2vw + 40px)
}
.autocomplete {
  position: relative;
  width: 130px;
}

.autocomplete-results {
  padding: 0;
  margin: 0;
  border: 1px solid #eeeeee;
  height: 120px;
  overflow: auto;
  width: 100%;
}

.autocomplete-result {
  list-style: none;
  text-align: left;
  padding: 4px 2px;
  cursor: pointer;
}

.autocomplete-result.is-active,
.autocomplete-result:hover {
  background-color: #1d4894;
  color: white;
}
</style>
