<template>
  <div>
    <div class="divide-y-4 divide-gray-100 w-full">
      <div
        class="block md:hidden"
        v-for="item in filteredResult"
        :key="item.payment_ref"
      >
        <div class="md:flex-grow p-3">
          <h2 class="text-2xl font-medium text-gray-800 title-font mb-2">
            {{ item.customer_name }}
          </h2>
          <p class="leading-relaxed text-lg font-bold">
            {{ currencyFormat(item.Amount || item.amount) }}
          </p>
          <p class="leading-relaxed text-sm font-light">
            {{ item.revenue_item }}
          </p>
          <p class="leading-relaxed text-sm font-light">
            {{ stripeDate2(item.date_time) }}
          </p>
          <button
            @click="showDetails(item)"
            class="text-blue-primary inline-flex items-center mt-4 focus:outline-none"
          >
            view details
            <svg
              class="w-4 h-4 ml-2"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path d="M5 12h14"></path>
              <path d="M12 5l7 7-7 7"></path>
            </svg>
          </button>
        </div>
      </div>

      <div
        class="hidden md:block overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative"
      >
        <table
          id="searchResult"
          class="search-result border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative"
        >
          <thead>
            <tr class="text-left">
              <th
                v-for="(heading, i) in headings"
                :key="i"
                class="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs"
              >
                <span>{{ heading }}</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in filteredResult"
              :key="item.payment_ref"
              @click="showDetails(item)"
              class="cursor-pointer hover:bg-gray-200 hover:font-semibold text-sm font-medium"
            >
              <td class="border-dashed border-t border-gray-200">
                <span
                  class="text-gray-700 px-6 py-3 flex items-center"
                  v-text="item.customer_name"
                ></span>
              </td>
              <td class="border-dashed border-t border-gray-200">
                <span
                  class="text-gray-800 px-6 py-3 flex items-center w-48"
                  v-text="currencyFormat(item.Amount || item.amount)"
                ></span>
              </td>
              <td class="border-dashed border-t border-gray-200">
                <span
                  class="text-gray-700 px-6 py-3 flex items-center"
                  v-text="item.revenue_item"
                ></span>
              </td>
              <td class="border-dashed border-t border-gray-200">
                <span
                  class="text-gray-700 px-6 py-3 flex items-center"
                  v-text="item.bank"
                ></span>
              </td>
              <td class="border-dashed border-t border-gray-200 emailAddress">
                <span
                  class="text-gray-700 px-2 py-3 flex items-center"
                  v-text="stripeDate2(item.date_time)"
                ></span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <teleport to=".modal">
      <DetailsModal
        title="Payment Information"
        v-if="isDetails"
        @hideModal="isDetails = false"
      >
        <div class="text-left border-t border-gray-100 md:h-96 overflow-y-auto">
          <dl>
            <div
              class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Name</dt>
              <dd
                class="mt-1 text-md text-gray-700 sm:mt-0 sm:col-span-2 font-semibold"
              >
                {{ details.customer_name }}
              </dd>
            </div>
            <div
              class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Amount</dt>
              <dd
                class="mt-1 text-lg text-gray-700 sm:mt-0 sm:col-span-2 font-semibold"
              >
                {{ currencyFormat(details.Amount || details.amount) }}
              </dd>
            </div>
            <div
              class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">
                Payment Date and Time
              </dt>
              <dd
                class="mt-1 text-lg text-gray-700 sm:mt-0 sm:col-span-2 font-semibold"
              >
                {{ stripeDate2(details.date_time) }}
              </dd>
            </div>
            <div
              class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Bank</dt>
              <dd
                class="mt-1 text-lg text-gray-700 sm:mt-0 sm:col-span-2 font-semibold"
              >
                {{ details.bank }}
              </dd>
            </div>
            <div
              class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Revenue Item</dt>
              <dd
                class="mt-1 text-md text-gray-700 sm:mt-0 sm:col-span-2 font-semibold"
              >
                {{ details.revenue_item }}
              </dd>
            </div>
            <div
              class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Bank Branch</dt>
              <dd
                class="mt-1 text-md text-gray-700 sm:mt-0 sm:col-span-2 font-semibold"
              >
                {{ details.bank_branch }}
              </dd>
            </div>
            <div
              class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt class="text-sm font-medium text-gray-500">Payment Ref</dt>
              <dd
                class="mt-1 text-md text-gray-700 sm:mt-0 sm:col-span-2 font-semibold"
              >
                {{ details.payment_ref_no }}
              </dd>
            </div>
          </dl>
        </div>
      </DetailsModal>
    </teleport>
  </div>
</template>
<script>
import DetailsModal from "@/components/AuthorizeModal";
import moment from "moment";
import { globalMixin } from "@/mixins/global.js";
// import { emitter } from "../../main.js";
import { handleExcelExport } from "../../helpers/json_export.js";
export default {
  name: "SearchResult",
  components: { DetailsModal },
  emits: ["exportDone"],
  props: {
    filteredResult: {
      required: true,
    },
    exportExcel: {
      required: true,
      default: false,
      type: Boolean,
    },
  },
  mixins: [globalMixin],
  watch: {
    async exportExcel(val) {
      if (val) {
        await handleExcelExport(
          "searchResult",
          "sheet1",
          "tpdb-" + Date.now().toString()
        );
        this.$emit("exportDone");
      }
    },
  },

  data() {
    return {
      headings: [
        "Customer Name",
        "Amount",
        "Revenue Item",
        "Bank",
        "Payment Date",
      ],
      isDetails: false,
      details: {},
      tableExport: null,
    };
  },

  methods: {
    showDetails(item) {
      this.details = item;
      this.isDetails = true;
    },
    stripeDate(dt) {
      let dateAlone = dt.split(" ")[0];
      // let timeAlone = dt.split(" ")[1];

      let dateSeparate = dateAlone.replace(/\//g, "-").split("-");

      if (dateSeparate[1] > 12) {
        let swap = dateSeparate[0];
        dateSeparate[0] = dateSeparate[1];
        dateSeparate[1] = swap;
      }

      return moment(
        `${dateSeparate[0]}-${dateSeparate[1]}-${dateSeparate[2]}`,
        `DD-${dateSeparate[1].length > 2 ? "MMM" : "MM"}-YYYY`
      ).format("Do MMMM, YYYY");
    },
    stripeDate2(dt) {
      return moment(dt).format("Do MMMM, YYYY");
    },
    stripeAmount(amount) {
      return +amount.replace(/,/g, "").replace("N", "");
    },
  },

  // mounted() {
  // emitter.on("download_excel", async () => {
  //   await handleExcelExport(
  //     "searchResult",
  //     "sheet1",
  //     "tpdb-" + Date.now().toString()
  //   );
  // });
  // },
};
</script>
