<template>
  <div>
    <div
      v-if="canViewTpdb"
      class="h-screen single flex flex-col items-start px-5"
    >
      <div class="w-full sm:flex items-center justify-items-center my-4">
        <p class="text-gray-500 font-medium focus:outline-none mr-4">
          Tax Payer's Data Bank
        </p>

        <DropDown
          @toggleFilter="toggleFilter"
          @setNewFilter="setNewFilter"
          :filters="state.filters"
          @away="away('filter')"
          :filter="state.filter"
          :selectedFilter="state.selectedFilter"
          :defaultFilter="state.defaultFilter"
        />
      </div>

      <div class="w-full">
        <auto-complete @search="searchPayer" v-if="!state.isDropdown" />

        <div class="w-full relative" v-else>
          <DropDown
            @toggleFilter="toggleRevenueItem"
            @setNewFilter="setNewRevenueItem"
            @away="away('revenueItem')"
            :filters="state.revenueItems"
            :filter="state.filterRevenueItem"
            :selectedFilter="state.selectedRevenueItem"
            :fullWidth="true"
          />
          <!-- <DropDown v-if="state.selectedFilter == 'Revenue Item'"  @toggleFilter="toggleRevenueItem" @setNewFilter="setNewRevenueItem" @away="away('revenueItem')" :filters ="state.revenueItems" :filter="state.filterRevenueItem" :selectedFilter="state.selectedRevenueItem"  :fullWidth="true"  /> -->

          <button
            class="icon-button h-12 right-0 my-auto absolute border-2 border-gray-400 bg-gray-200 leading-snug font-normal text-center text-gray-400 bg-transparent rounded-tr-lg rounded-br-lg text-base"
            @click="fetchPaymentsByRevenueHead"
          >
            <i class="fas fa-search items-center justify-center"></i>
          </button>
          <div class="w-full flex flex-wrap mb-3">
            <div
              class="dateRange w-full sm:flex sm:items-center sm:space-x-7 mt-4 sm:mt-6 sm:order-first sm:mr-10"
            >
              <date-picker
                :newDate="state.selectedDates.startDate"
                :classList="[' mb-2 sm:mb-0  sm:ml-0']"
                :title="'Start'"
                @updateDate="updateStartDate"
              ></date-picker>
              <date-picker
                :newDate="state.selectedDates.endDate"
                :title="'End'"
                @updateDate="updateEndDate"
                :classList="['  sm:ml-0']"
              ></date-picker>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="noResult && !state.loading"
        class="w-full border rounded-lg bg-primary text-white font-medium py-4 text-center mt-4"
      >
        <span>{{ state.msg }}</span>
      </div>

      <div
        class="w-full flex flex-wrap"
        v-if="state.items && state.items.length > 0 && !state.isDropdown"
      >
        <div
          class="dateRange w-full space-y-4 mt-4 sm:mt-6 sm:order-first sm:mr-10"
        >
          <date-picker
            :newDate="state.selectedDates.startDate"
            :title="'Start'"
            @updateDate="updateStartDate"
          >
          </date-picker>
          <date-picker
            :newDate="state.selectedDates.endDate"
            :title="'End'"
            @updateDate="updateEndDate"
          >
          </date-picker>
        </div>
        <div class="w-full flex items-start md:w-52 mt-4 sm:mt-6 sm:mr-10">
          <button
            class="w-full md:w-42 bg-gray-200 py-2 px-4 rounded-lg focus:outline-none ring-1 ring-gray-300"
            @click="filterByDate"
          >
            Filter
            <i class="fas fa-filter items-center justify-center"></i>
          </button>
          <button
            v-if="state.isFiltering"
            class="w-full md:w-60 bg-gray-200 py-2 px-4 md:px-2 ml-2 md:ml-3 rounded-lg focus:outline-none ring-1 ring-gray-300"
            @click="clearFilter"
          >
            Clear Filter
            <i class="fas fa-clear items-center justify-center"></i>
          </button>
        </div>
      </div>

      <!-- class="w-full mt-5 flex flex-wrap justify-start md:space-x-4 items-end text-md md:text-2xl" -->
      <div
        v-if="showResult && !state.loading"
        class="w-full mt-5 flex flex-col md:flex-row md:items-center space-y-3 md:space-y-0 md:space-x-2 lg:space-x-4 text-lg md:text-xl lg:text-2xl"
      >
        <div
          class="flex w-full md:w-1/3 lg:w-1/4 md:p-4 p-2 border border-gray-200 bg-blue-primary rounded shadow-sm text-gray-100 items-center justify-center"
        >
          <!-- <span class=" md:block mr-1">Total: </span> -->
          <span class="font-medium sm:font-bold">{{
            currencyFormat(total)
          }}</span>
        </div>
        <div
          class="flex items-center w-full md:w-1/3 lg:w-1/4 md:p-4 p-2 border border-gray-200 bg-blue-primary rounded shadow-sm text-gray-100 justify-center"
        >
          <span class="ml-1 font-medium sm:font-bold">
            {{ state.filteredResult.length }} Txn(s)</span
          >
        </div>
        <button
          id="btnLogin"
          type="button"
          @click.prevent.stop="exportExcelData"
          :disabled="state.exportingExcel"
          class="md:p-4 p-2 w-full md:w-1/3 lg:w-1/4 sm:w-auto font-semibold text-white transition duration-500 ease-in-out transform rounded shadow-xl bg-gradient-to-r from-green-700 hover:from-green-600 to-green-600 hover:to-green-700 focus:ring focus:ring-green-700 focus:outline-none"
        >
          <div v-if="!state.exportingExcel">
            Export excel
            <i class="fas fa-download ml-3"></i>
          </div>
          <div v-else class="flex items-center space-x-4 justify-center">
            <i class="fas fa-circle-notch animate-spin"></i>
            <span>Please wait...</span>
          </div>
        </button>
      </div>

      <div
        class="w-full search-result mt-10 mb-5 text-gray-600 body-font rounded-lg pb-10"
      >
        <div class="bg-transparent pb-4 text-center">
          <i
            v-if="state.loading"
            :class="`lg:focus:shadow-sm w-12 text-center fas fa-circle-notch mt-4 text-5xl text-blue-primary animate-spin`"
          ></i>
        </div>

        <div
          class="divide-y-2 divide-gray-100 bg-gray-200"
          v-if="showResult && !state.loading"
        >
          <SearchResult
            @exportDone="exportExcel = false"
            :exportExcel="exportExcel"
            :filteredResult="state.filteredResult"
          />
        </div>
      </div>
    </div>
    <content-holder v-else :title="'Taxpayer Data Bank'" class="p-6">
      <unauthorized-access></unauthorized-access>
    </content-holder>
  </div>
</template>
<script>
import DatePicker from "@/components/DatePicker2.vue";
import Autocomplete from "@/components/Search/AutoComplete.vue";
import DropDown from "@/components/Search/DropDown.vue";
import SearchResult from "@/components/Search/SearchResult.vue";
import { reactive, computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
// import { useRoute } from "vue-router";
import { globalMixin } from "@/mixins/global.js";
// import { emitter } from "../main";
import UnauthorizedAccess from "../components/shared/UnauthorizedAccess.vue";
import ContentHolder from "../components/ContentHolder.vue";
import { views, unit, mda, designation } from "@/helpers/login_details.js";
export default {
  name: "Search",
  components: {
    DatePicker,
    "auto-complete": Autocomplete,
    UnauthorizedAccess,
    ContentHolder,
    DropDown,
    SearchResult,
  },
  data: () => ({
    canViewTpdb: false,
  }),
  mixins: [globalMixin],
  setup() {
    const exportExcel = ref(false);
    const state = reactive({
      search: "",
      items: [],
      filteredResult: [],
      msg: "You've not searched yet, enter search criteria",
      isFiltering: false,
      loading: false,
      selectedDates: {
        startDate: new Date(),
        endDate: new Date(),
      },
      filter: false,
      filters: [
        "Tax Payer",
        "Revenue Item",
        "Revenue Category",
        "Bank",
        "Payment Channels",
      ],
      revenueItems: [],
      filterRevenueItem: false,
      selectedRevenueItem: "",
      selectedFilter: "Select Filter",
      defaultFilter: "Select Filter",
      isDropdown: false,
      exportingExcel: false,
    });
    const store = useStore();
    // const route = useRoute();
    const searchPayer = async (criteria) => {
      state.items = state.filteredResult = [];
      if (!criteria && criteria == "") {
        state.msg = "Enter a valid search criteria";
      } else {
        state.loading = true;
        const result = await store.dispatch("searchPayer", {
          isStale: false,
          criteria: criteria,
        });

        state.items = state.filteredResult = result;
        state.loading = false;
        state.msg = "No match found, change your filter";
      }
    };
    const filterByDate = () => {
      let newRes = state.items.filter((item) => {
        return (
          new Date(state.selectedDates.startDate.toDateString()).getTime() <=
            new Date(
              new Date(item.payment_date_time).toDateString()
            ).getTime() &&
          new Date(state.selectedDates.endDate.toDateString()).getTime() >=
            new Date(new Date(item.payment_date_time).toDateString()).getTime()
        );
      });
      state.isFiltering = true;
      state.filteredResult = newRes;
    };
    const clearFilter = () => {
      state.isFiltering = false;
      state.filteredResult = state.items;
    };
    const updateStartDate = (newDate) => {
      state.selectedDates.startDate = newDate;
    };
    const updateEndDate = (newDate) => {
      state.selectedDates.endDate = newDate;
    };
    const toggleFilter = () => {
      state.filter = !state.filter;
    };
    const toggleRevenueItem = () => {
      state.filterRevenueItem = !state.filterRevenueItem;
    };
    const away = (type) => {
      if (type == "filter") {
        state.filter = false;
      } else if (type == "revenueItem") {
        state.filterRevenueItem = false;
      }
    };
    const filterRevenueItemsForDesignation = (val) => {
      if (designation.toLowerCase().includes(val)) {
        state.revenueItems = state.revenueItems.filter((item) => {
          if (item.revenue_item)
            return (
              item.revenue_item.toLowerCase().includes("gaming") ||
              item.revenue_item.toLowerCase().includes("betting")
            );
          else return item;
        });
      }
    };
    const setNewFilter = async (filter) => {
      state.items = state.filteredResult = [];
      state.selectedFilter = filter;
      state.filter = false;

      if (state.selectedFilter !== "Tax Payer") {
        state.loading = true;
        state.isDropdown = true;
        let revenueItems;

        if (state.selectedFilter !== "Payment Channels") {
          revenueItems = await store.dispatch("fetchItemsToFilter", filter);
        } else {
          revenueItems = ["All", "Bank", "POS", "Internet", "IFIS"];
        }

        state.revenueItems = revenueItems;

        filterRevenueItemsForDesignation("betting");

        if (state.revenueItems[0].revenue_item)
          state.selectedRevenueItem = state.revenueItems[0].revenue_item;
        else state.selectedRevenueItem = state.revenueItems[0];

        state.loading = false;
      } else {
        state.isDropdown = false;
      }
    };
    const setNewRevenueItem = async (filter) => {
      state.selectedRevenueItem = filter.revenue_item
        ? filter.revenue_item
        : filter;
      state.filterRevenueItem = false;

      // if(state.selectedFilter == "Revenue Item"){
      // }
    };
    const fetchPaymentsByRevenueHead = async () => {
      state.loading = true;
      let startDate = state.selectedDates.startDate;
      let endDate = state.selectedDates.endDate;
      let result = await store.dispatch("fetchItemsByFilter", {
        filter: state.selectedFilter,
        item: state.selectedRevenueItem,
        startDate: `${startDate.getFullYear()}-${
          startDate.getMonth() + 1
        }-${startDate.getDate()}`,
        endDate: `${endDate.getFullYear()}-${
          endDate.getMonth() + 1
        }-${endDate.getDate()}`,
      });

      state.items = state.filteredResult = result;
      state.loading = false;
      state.msg = "No match found, change your filter";
    };
    const total = computed(() => {
      if (state.filteredResult && state.filteredResult.length > 0) {
        return state.filteredResult.reduce((acc, curr) => {
          // let amount = curr.amount.trim().replace(/,/g, "").replace("N", "");
          // console.log(acc, +acc + +curr.amount, curr, curr.amount);
          let amount = curr.Amount || curr.amount;
          return +acc + +amount;
        }, 0);
      } else {
        return 0;
      }
    });
    const noResult = computed(() => {
      if (state.items == undefined || state.filteredResult == undefined)
        return true;
      if (state.items.length < 1) return true;

      return false;
    });
    const showResult = computed(() => {
      return state.filteredResult && state.filteredResult.length > 0;
    });
    const selectedItem = computed(() => {
      // ["Tax Payer", "Revenue Item", "Revenue Category"]
      if (
        state.selectedFilter == state.defaultFilter ||
        state.selectedFilter == "Tax Payer"
      ) {
        return "showCustomer";
      } else if (state.selectedFilter == "Revenue Item") {
        return "showRevenueItem";
      } else {
        return "showRevenueCategory";
      }
    });

    onMounted(() => {
      try {
        if (
          ![
            "chairman",
            "administrator",
            "technical adviser",
            "assessment",
          ].includes(unit.toLowerCase())
        ) {
          if (
            unit.toLowerCase() != "collections" &&
            !["director", "unit head"].includes(designation.toLowerCase())
          ) {
            state.filters = ["Revenue Item", "Revenue Category", "Bank"];
            setNewFilter(state.filters[0]);
            state.isDropdown = true;
            state.msg = "No match found, change your filter";
          }
        }

        if (designation.toLowerCase().includes("betting")) {
          state.filters = ["Revenue Item"];
          setNewFilter(state.filters[0]);
          state.isDropdown = true;
          state.msg = "No match found, change your filter";
        }

        if (mda != "1") {
          state.filters = [
            "Tax Payer",
            "Revenue Item",
            "Revenue Category",
            "Bank",
          ];
          setNewFilter(state.filters[0]);
          state.isDropdown = false;
          state.msg = "You've not searched yet, enter search criteria";
        }
        // const name = route.params.name || "";
        // searchPayer(name);
      } catch (error) {
        console.log(error);
      }
    });
    const exportExcelData = () => {
      try {
        state.exportingExcel = true;
        exportExcel.value = true;
      } catch (error) {
        console.log(error);
        state.exportingExcel = false;
      } finally {
        state.exportingExcel = false;
      }
    };
    return {
      state,
      exportExcel,
      exportExcelData,
      searchPayer,
      filterByDate,
      clearFilter,
      updateStartDate,
      updateEndDate,
      total,
      noResult,
      showResult,
      away,
      selectedItem,
      setNewFilter,
      toggleFilter,
      setNewRevenueItem,
      toggleRevenueItem,
      fetchPaymentsByRevenueHead,
    };
  },
  created() {
    if (views.includes("Tpdb")) {
      this.canViewTpdb = true;
    } else {
      this.canViewTpdb = false;
    }
  },
};
</script>
