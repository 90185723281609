<template>
  <div
    :class="`${fullWidth ? 'w-full' : ' mt-2 sm:mt-0 sm:w-dropdown-w w-full'}`"
    class="relative inline-block text-left"
  >
    <div>
      <button
        type="button"
        @click.stop="toggleFilter(!filter)"
        :class="`${fullWidth ? 'h-12 w-full' : 'sm:w-dropdown-w w-full'}`"
        class="inline-flex items-center justify-between rounded-md border-2 border-gray-300 shadow-md px-4 py-2 bg-white text-sm font-semibold text-gray-400 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-primary"
        id="options-menu"
        aria-haspopup="true"
        aria-expanded="true"
      >
        {{
          selectedFilter == "Revenue Category"
            ? "Ministries, Departments & Agencies"
            : selectedFilter.revenue_item
            ? selectedFilter.revenue_item
            : selectedFilter
        }}
        <i
          :class="`fas ${
            filter ? 'fa-angle-down' : 'fa-angle-up'
          } ml-4 text-md`"
        ></i>
      </button>
    </div>

    <div
      v-click-away="away"
      v-if="filter"
      :class="`${fullWidth ? 'w-full' : 'sm:w-dropdown-w w-full'}`"
      class="z-50 origin-top-left absolute left-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
    >
      <div
        class="py-1"
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="options-menu"
      >
        <p
          ref="filter_item"
          v-for="filter in filters"
          :key="filter"
          @click="setNewFilter(filter)"
          class="filter_item cursor-pointer block px-4 py-2 text-sm text-gray-600 hover:bg-gray-100 hover:text-gray-800"
          role="menuitem"
        >
          {{
            filter == "Revenue Category"
              ? "Ministries, Departments & Agencies"
              : filter.revenue_item
              ? filter.revenue_item
              : filter
          }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    fullWidth: {
      type: Boolean,
    },
    filters: {
      required: true,
    },
    filter: {
      type: Boolean,
    },
    selectedFilter: {
      default: "Select Filter",
    },
    defaultFilter: {
      default: "",
    },
  },

  methods: {
    toggleFilter(filter) {
      this.$emit("toggleFilter", filter);
    },
    setNewFilter(filter) {
      this.$emit("setNewFilter", filter);
    },
    away(e) {
      if (this.$refs.filter_item) {
        if (e.srcElement.className !== this.$refs.filter_item.className)
          this.$emit("away");
      }
    },
  },
};
</script>
<style>
@media screen and (max-width: 450px) {
  .dropdown-w {
    width: 100% !important;
  }
}

/* @media screen and (min-width: 451px) {
  .dropdown-w {
    width: 270px !important;
    padding-right: 0px !important;
  }
} */
</style>
